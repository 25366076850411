import {
  Edit,
  ArrayInput,
  TextInput,
  NumberInput,
  BooleanInput,
  SimpleFormIterator,
  SelectInput,
  ReferenceManyField,
  TextField,
  EditButton,
  Datagrid,
  ReferenceInput,
  TabbedForm,
  FormTab,
  NumberField,
  ReferenceField,
  ArrayField,
  SingleFieldList,
  ChipField,
  useRecordContext,
} from "react-admin";
import { ShowButton } from "react-admin";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

// import AddIcon from "@mui/icons/AddCircleOutline";

const getNextExerciseOrder = (lessonRecord: any): number => {
  if (lessonRecord == null || lessonRecord.lessonExercises == null) {
    return 0;
  }
  return (
    Math.max(
      Math.max(...lessonRecord.lessonExercises.map((item: any) => item.order)),
      0
    ) + 1
  );
};

// const AddOneOnlyButton = (props: any) => {
//   const record = useRecordContext(props);
//   console.log(record);
//   console.log(props.onClick);
//   return (
//     <Button
//       label="ra.action.add"
//       onClick={() => {
//         if (record.translates.length === 0) {
//           console.log("Translates is 0");
//           return props.onClick;
//         } else {
//           console.log("Translates is 2 or more");
//           return null;
//         }
//       }}
//     >
//       {/* <AddIcon /> */}
//     </Button>
//   );
// };

const CreateExercise = () => {
  const record = useRecordContext();
  const curState = {
    record: {
      lessonExercises: [
        { order: getNextExerciseOrder(record), lessonId: record.id },
      ],
    },
  };
  return (
    <Button
      component={Link}
      className="ra.action.create"
      to={{
        pathname: "/exercises/create",
      }}
      state={curState}
    >
      <p> Create Exercise</p>
    </Button>
  );
};

// const ExerciseShowButton = ({ record }: any) => (
//   <ShowButton basePath="/exercises" label="Show exercise" record={record} />
// );

export const LessonEdit = (props: any) => {
  return (
    <Edit {...props}>
      <TabbedForm
        warnWhenUnsavedChanges
        // submitOnEnter={false}
        syncWithLocation={false}
        mutationMode="pessimistic"
      >
        <FormTab label="Lesson Type">
          <ReferenceInput source="lessonTypeId" reference="lessonTypes">
            <SelectInput optionText="type" />
          </ReferenceInput>
        </FormTab>

        <FormTab label="Main Details">
          <ReferenceInput source="lessonTypeId" reference="lessonTypes">
            <SelectInput optionText="type" />
          </ReferenceInput>

          <ArrayInput source="courseLessons">
            <SimpleFormIterator disableAdd>
              <NumberInput source="order" />
            </SimpleFormIterator>
          </ArrayInput>
          <TextInput source="video" />
          <BooleanInput source="isPublic" />
          <ReferenceInput source="lessonTypeId" reference="lessonTypes">
            <SelectInput optionText="type" />
          </ReferenceInput>
        </FormTab>
        <FormTab label="Exercises">
          <CreateExercise />
          <ReferenceManyField
            reference="exercises"
            target="lessonExercises.lessonId"
            label="Exercises"
            perPage={100}
          >
            <Datagrid>
              <TextField source="content" />
              <TextField source="question" />
              <NumberField source="complexity" />
              <ArrayField
                label="Order"
                source="lessonExercises"
                sortBy="lessonExercises.order"
              >
                <SingleFieldList>
                  <ChipField source="order" />
                </SingleFieldList>
              </ArrayField>
              <ArrayField
                label="Version"
                source="lessonExercises"
                sortBy="lessonExercises.version"
              >
                <SingleFieldList>
                  <ChipField source="version" />
                </SingleFieldList>
              </ArrayField>
              <TextField source="maxTryCount" />
              <ReferenceField
                label="Excercise Type"
                source="exerciseTypeId"
                reference="exerciseTypes"
              >
                <TextField source="type" />
              </ReferenceField>
              <ReferenceField
                label="Excercise Category"
                source="exerciseCategoryId"
                reference="exerciseCategories"
              >
                <TextField source="name" />
              </ReferenceField>
              <EditButton />
              {/* <ExerciseShowButton /> */}
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Lesson Description">
          <ArrayInput source="translates">
            <SimpleFormIterator>
              <TextInput
                sx={{
                  textAlignLast: "right",
                }}
                source="title"
              />
              <TextInput source="subtitle" />
              <TextInput source="shortDescription" />
              <TextInput source="description" />
              <ReferenceInput source="languageId" reference="languages">
                <SelectInput optionText="name" />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
