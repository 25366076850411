import {
  TextField,
  BooleanField,
  ArrayField,
  SingleFieldList,
  TextInput,
  Datagrid,
  List,
  RichTextField,
  Button,
  Link,
} from "react-admin";
import { LessonFilterSidebar } from "./LessonFilterAside";

export const LessonList = (props: any) => {
  const lessonFilter = [
    <TextInput label="Search SEO" source="seoDescription" alwaysOn />,
  ];

  return (
    <>
      <Button
        label="Create Game Lesson"
        component={Link as any}
        to={{
          pathname: "/lesson/create",
          state: { record: { lessonTypeId: "game" } },
        }}
      />
      <List {...props} filters={lessonFilter} aside={<LessonFilterSidebar />}>
        <Datagrid rowClick="edit">
          <BooleanField source="isPublic" />
          <RichTextField textAlign="right" source="seoDescription" />
          <TextField source="lessonType.type" />
          <ArrayField source="courseLessons" sortBy="courseLessons.order">
            <SingleFieldList linkType="false">
              <TextField source="order" />
            </SingleFieldList>
          </ArrayField>
          <ArrayField
            source="courseLessons"
            sortBy="courseLessons.course.level"
          >
            <SingleFieldList linkType="false">
              <TextField source="course.level" />
            </SingleFieldList>
          </ArrayField>
          <ArrayField source="translates">
            <Datagrid>
              <TextField source="title" />
              <TextField source="subtitle" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="translates">
            <Datagrid>
              <TextField source="title" />
              <TextField source="subtitle" />
            </Datagrid>
          </ArrayField>
        </Datagrid>
      </List>
    </>
  );
};
