import {
  List,
  Datagrid,
  TextField,
  ArrayField,
  TopToolbar,
  CreateButton,
  ExportButton,
  ReferenceField,
} from "react-admin";

const ListActions = (props: any) => (
  <TopToolbar>
    <ExportButton />
    <CreateButton />
  </TopToolbar>
);

export const CourseList = (props: any) => (
  <List
    {...props}
    title="inglisi Courses"
    actions={<ListActions />}
    sort={{ field: "level", order: "ASC" }}
  >
    <Datagrid rowClick="edit">
      <TextField source="level" />
      <TextField source="language" />
      <ArrayField source="translates">
        <Datagrid>
          <TextField source="description" />
          <TextField source="title" />
          <ReferenceField source="languageId" reference="languages">
            <TextField source="name" />
          </ReferenceField>
        </Datagrid>
      </ArrayField>
    </Datagrid>
  </List>
);
