import { Admin, Resource, ListGuesser, EditGuesser } from "react-admin";
import { CourseList } from "./courses/CourseList";
import { CourseEdit } from "./courses/CourseEdit";
import { CourseShow } from "./courses/CourseShow";
import { LessonList } from "./lessons/LessonsList";
import { LessonEdit } from "./lessons/LessonEdit";
import { LessonCreate } from "./lessons/LessonCreate";
import { TestimonialList } from "./testimonials/TestimonialList";
import { TestimonialEdit } from "./testimonials/TestimonialEdit";
import { TestimonialCreate } from "./testimonials/TestimonialCreate";
import { ExerciseList } from "./exercises/ExercisesList";
import { ExerciseEdit } from "./exercises/ExercisesEdit";
import { ExerciseShow } from "./exercises/ExercisesShow";
import { authProvider } from "./utilities/auth";
import { ExerciseCreate } from "./exercises/ExercisesCreate";
import { CourseCreate } from "./courses/CourseCreate";
import { PanelUserList } from "./panelUsers/panelUsersList";
import { PanelUserEdit } from "./panelUsers/panelUsersEdit";
import { PanelUserCreate } from "./panelUsers/panelUsersCreate";
import { PanelOrganizationList } from "./panelOrganizations/panelOrganizationsList";
import { PanelOrganizationEdit } from "./panelOrganizations/panelOrganizationsEdit";
import { PanelOrganizationCreate } from "./panelOrganizations/panelOrganizationsCreate";
import { dataProvider, expandedDataProvider } from "./utilities/dataprovider";
function App() {
  return (
    <Admin
      title={"Admin Panel"}
      dataProvider={expandedDataProvider}
      authProvider={authProvider}
      // loginPage={MyLoginPage}
    >
      <Resource
        name={"courses"}
        list={CourseList}
        edit={CourseEdit}
        show={CourseShow}
        create={CourseCreate}
      />
      <Resource
        name={"lessons"}
        list={LessonList}
        edit={LessonEdit}
        create={LessonCreate}
      />
      <Resource
        name={"testimonials"}
        list={TestimonialList}
        edit={TestimonialEdit}
        create={TestimonialCreate}
      />
      <Resource name="users" />
      <Resource
        name="exercises"
        list={ExerciseList}
        edit={ExerciseEdit}
        create={ExerciseCreate}
        show={ExerciseShow}
      />
      <Resource name="lessonTypes" />
      <Resource name="exerciseTypes" />
      <Resource
        name="exerciseCategories"
        list={ListGuesser}
        edit={EditGuesser}
        show={ExerciseShow}
      />
      <Resource name="xpPoints" />
      <Resource name="languages" list={ListGuesser} edit={EditGuesser} />
      <Resource
        name="panelUsers"
        list={PanelUserList}
        edit={PanelUserEdit}
        create={PanelUserCreate}
      />
      <Resource
        name="panelOrganizations"
        list={PanelOrganizationList}
        edit={PanelOrganizationEdit}
        create={PanelOrganizationCreate}
      />
    </Admin>
  );
}

export default App;
