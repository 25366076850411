//Addimg the JWT token to the headers of the request
//and is added as a second parameter to the dataProvider below

import { fetchUtils } from "react-admin";
import crudProvider from "ra-data-nestjsx-crud";

const httpClient = (url: any, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const { access_token } = JSON.parse(localStorage.getItem("auth") as string);
  options.headers.set("Authorization", `Bearer ${access_token}`);
  return fetchUtils.fetchJson(url, options);
};

export const dataProvider = crudProvider(
  process.env.REACT_APP_BACKEND as string,
  httpClient
);

const uploadFile = async (file) => {
  const { access_token } = JSON.parse(localStorage.getItem("auth") as string);
  const formData = new FormData();
  formData.append("file", file.rawFile);

  const ret = await fetch(`${process.env.REACT_APP_BACKEND}/fileupload`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
    method: "POST",
    body: formData,
  });
  const jsonResp = await ret.json();
  return jsonResp;
};

export const expandedDataProvider = {
  ...dataProvider,
  create: async (resource, params) => {
    //If the supplied does not have an image and that image does not have a rawFile
    //return the normal create function without any image upload
    if (!params.data.image || !params.data.image.rawFile)
      return dataProvider.create(resource, params);
    const newImage = await uploadFile(params.data.image);
    console.log("New image is " + newImage);
    params.data.image = newImage;
    return dataProvider.create(resource, params);
  },
};
