import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { Role } from "../utilities/enums/roles.enum";
import { CreateSelectArrayFromEnum } from "../utilities/utils";

export const PanelUserEdit = (props: any) => {
  const roleDict = CreateSelectArrayFromEnum(Role);

  console.log(roleDict);
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="username" />
        <TextInput source="email" label="E-mail" />
        <SelectInput source="roles" choices={roleDict} label="Role" />
        <ReferenceInput source="organizationId" reference="panelOrganizations">
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};
