import { TextField } from "@mui/material";
import { Labeled } from "react-admin";
import { SketchPicker } from "react-color";
import { useController } from "react-hook-form";

export const ColorInput = ({ name, label, helperText }) => {
  const { field } = useController({ name, defaultValue: "#999" });

  return (
    <>
      <p> {helperText} </p>
      <Labeled label="Color">
        <SketchPicker
          color={field.value}
          onChange={field.onChange}
          label={label}
        />
      </Labeled>
    </>
  );
};
