import {
  Edit,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
  TextField,
  SimpleForm,
} from "ra-ui-materialui";
import { useGetIdentity } from "react-admin";
import { Languages } from "../utilities/enums/languages.enum";
import { CreateSelectArrayFromEnum } from "../utilities/utils";

export const CourseEdit = (props: any) => {
  const langDict = CreateSelectArrayFromEnum(Languages);
  const { identity } = useGetIdentity();

  const TransformBeforeSubmission = (data: any, { previousData }: any) => {
    console.log(identity);
    return {
      ...data,
      previousData: previousData,
      user: identity,
    };
  };

  return (
    <Edit {...props} transform={TransformBeforeSubmission}>
      <SimpleForm>
        <TextInput source="level" />
        <SelectInput source="language" choices={langDict} />
        <ArrayInput source="translates">
          <SimpleFormIterator>
            <ReferenceInput source="languageId" reference="languages">
              <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="title" />
            <TextInput multiline source="description" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};
