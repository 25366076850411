import { Card as MuiCard, CardContent, withStyles } from "@mui/material";

import { CoursesFilter } from "./LessonFilterList";

// const Card = withStyles((theme: any) => ({
//   root: {
//     [theme.breakpoints.up("sm")]: {
//       order: -1, // display on the left rather than on the right of the list
//       width: "20em",
//       marginRight: "1em",
//     },
//     [theme.breakpoints.down("sm")]: {
//       display: "none",
//     },
//   },
// }))(MuiCard);

export const LessonFilterSidebar = () => (
  <div>
    <CardContent>
      <CoursesFilter />
    </CardContent>
  </div>
);
