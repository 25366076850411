import {
  ArrayInput,
  TextInput,
  NumberInput,
  BooleanInput,
  SimpleFormIterator,
  SelectInput,
  ReferenceInput,
  Create,
  RaRecord,
  SimpleForm,
  useGetList,
  Loading,
} from "react-admin";
import { ContentEditor } from "../customInputs/ContentEditor";
import { useWatch } from "react-hook-form";

export const LessonCreate = (props: any) => {
  const LessonCreateTransformer = (data: RaRecord) => {
    for (var i = 0; i < data.translates.length; i++) {
      data.translates[i].topics = data.translates[i].topics.split(";");
    }
    console.log(data);
    return data;
  };

  // const LessonOrder = () => {
  //   const courseId = useWatch({name:})

  //   const { data, total, isLoading, error } = useGetList("lessons", {
  //     filter: { "courseLessons.courseId": courseId },
  //   });
  //   if (isLoading) {
  //     return <Loading />;
  //   }
  //   if (error) {
  //     return <p>ERROR</p>;
  //   }
  //   console.log(data);
  //   return <div>Loaded</div>;
  // };

  return (
    <Create
      {...props}
      transform={LessonCreateTransformer}
      mutationMode="pessimistic"
    >
      <SimpleForm
        warnWhenUnsavedChanges
        // Need to migrate to this
        // submitOnEnter={false}
      >
        <ReferenceInput source="lessonTypeId" reference="lessonTypes">
          <SelectInput
            optionText="type"
            optionValue="id"
            helperText="Select the lesson type"
          />
        </ReferenceInput>
        <ArrayInput
          source="courseLessons"
          label="Course and order"
          defaultValue={[{ order: 10 }]}
          // helperText="This lesson can be assigned to different levels. Please specify the level and the order of the lesson"
        >
          <SimpleFormIterator disableRemove>
            <NumberInput source="order" defaultValue={10} label="Order" />
            <ReferenceInput source="courseId" reference="courses">
              <SelectInput optionText="level" optionValue="id" label="Course" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput
          source="video"
          label="Video link"
          helperText="Add the link for the video"
        />
        <ContentEditor name="seoDescription" />
        <NumberInput
          source="nextTryInSeconds"
          label="Time between attempts"
          helperText="If left empty, the lesson would be immediately accessible after finishing it; otherwise input the number of seconds"
        />
        <NumberInput
          source="timeDurationSeconds"
          label="Expected time for this lesson"
          helperText="Input the expected time for this lesson in seconds"
        />

        <BooleanInput source="isPublic" label="Public Access" />
        <ArrayInput source="translates" defaultValue={[{}, {}]}>
          <SimpleFormIterator disableRemove>
            <ReferenceInput
              label="Language"
              source="languageId"
              reference="languages"
            >
              <SelectInput
                label="Language"
                optionText="name"
                optionValue="id"
              />
            </ReferenceInput>
            <TextInput label="Lesson title" source="title" />
            <TextInput label="Lesson Subtitle" source="subtitle" />
            <TextInput label="Description" source="description" />
            <TextInput label="Short Description" source="shortDescription" />
            <TextInput
              label="Topics"
              helperText="Please add the topics seperated by semicolons"
              source="topics"
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};
