import {
  List,
  Datagrid,
  TextField,
  ArrayField,
  TopToolbar,
  FilterButton,
  CreateButton,
  ExportButton,
  Button,
  ListBase,
  Title,
  ListToolbar,
  ListContextProvider,
  useListController,
  useListContext,
} from "react-admin";
import { useEffect, useState } from "react";
import { prepareExercisePostMessage } from "./ExerciseIFrameResponse";

export const ExerciseShow = ({ children, ...props }: any) => (
  <ListContextProvider value={useListController(props)}>
    <InternalExerciseShow {...props} />
  </ListContextProvider>
);

const InternalExerciseShow = ({ children, ...props }: any) => {
  const { data } = useListContext();
  const [lang, setLang]: any = useState("en");
  //   const [languagesArray, setLanguagesArray]:any = useState('')

  //   useEffect(()=> {
  //     const fetchData = async () => {
  //       const data = await fetch('http://localhost:8080/languages')

  //       // convert the data to json
  //       const json = await data.json()

  //       //map the result to the required format
  //       const mappedData = json.map((langRes:any) => langRes.name)

  //       // set state with the result
  //       setLanguagesArray(mappedData)
  //     }

  //     // call the function
  //     fetchData()
  //       // make sure to catch any error
  //       .catch(console.error);
  //   }, [])

  //   const handleSubmit = () => {

  //     const iframe = document.getElementById(
  //       `main-iframe`
  //     ) as HTMLIFrameElement;

  //     if (iframe?.contentWindow) {
  //       iframe.contentWindow.postMessage(prepareCoursePostMessage(data, lang, languagesArray), '*');
  //     }
  //   };

  return (
    <ListContextProvider value={useListController(props)}>
      {/* <>
        <Button
          onClick={() => setLang(lang === "en" ? "ar" : "en")}
          label="toggle"
        />
        <div>
          <div>
            <button onClick={handleSubmit}>
            Submit
            </button>

            {lang ? (
              <iframe
                title={"Preview page"}
                id="main-iframe"
                src={
                  "https://inglisi-dev.light-it.co/" + lang + "/preview-page"
                }
                height={500}
                width="70%"
              />
            ) : (
              <h1>Loading</h1>
            )}
          </div>
        </div>
      </> */}
    </ListContextProvider>
  );
};
