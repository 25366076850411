import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  ArrayInput,
  SelectInput,
  SimpleFormIterator,
  DateInput,
  ImageInput,
  ImageField,
} from "react-admin";
import { ColorInput } from "../customInputs/ColorInput";
import { ContentEditor } from "../customInputs/ContentEditor";

export const ExerciseEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      {/* <ContentEditor name="content" /> */}
      <TextInput source="description" />
      <NumberInput source="maxTryCount" />
      <NumberInput source="complexity" />
      <NumberInput source="totalQuestions" />
      <ImageField source="image" />
      {/* <ColorInput
        name="image"
        label="Color"
        helperText="Choose below the background color of the flashcard. It is optional."
      /> */}
      <TextInput source="help_hint" />
      <TextInput source="video" />
      <TextInput source="audio" />
      <TextInput source="videoTimestamp" />
      <TextInput source="additionalForId" />
      <ReferenceInput
        source="exerciseCategoryId"
        reference="exerciseCategories"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="exerciseTypeId" reference="exerciseTypes">
        <SelectInput optionText="type" />
      </ReferenceInput>
      <ArrayInput source="lessonExercises">
        <SimpleFormIterator>
          <NumberInput source="order" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="groupOptions">
        <SimpleFormIterator>
          <ReferenceInput source="exerciseId" reference="exercises">
            <SelectInput optionText="id" />
          </ReferenceInput>
          <ArrayInput source="answerOptions">
            <SimpleFormIterator>
              <TextInput source="answer" />
              <NumberInput source="count" />
              <ReferenceInput source="groupOptionId" reference="groupOptions">
                <SelectInput optionText="id" />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="rightAnswers">
        <SimpleFormIterator>
          <TextInput source="id" />
          <DateInput source="createdAt" />
          <DateInput source="updatedAt" />
          <ArrayInput source="answer">
            <SimpleFormIterator>
              <TextInput source="id" />
              <TextInput source="value" />
            </SimpleFormIterator>
          </ArrayInput>
          <TextInput source="audio" />
          <ReferenceInput source="exerciseId" reference="exercises">
            <SelectInput optionText="id" />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput source="additionalFor" />
    </SimpleForm>
  </Edit>
);
