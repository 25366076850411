import LanguageDetect from "languagedetect";

export const langDict = { english: "en", arabic: "ar", french: "fr" };

//Need to fix the typing for the langDict
export const getLanguage = (text: string): string => {
  const lngDetector = new LanguageDetect();
  const lang = lngDetector.detect(text, 2);
  return (langDict as any)[lang[0][0]];
};

export const CreateSelectArrayFromEnum = (enumType: any): any => {
  const dict = Object.values(enumType).map((it) => {
    return { id: it, name: it };
  });
  return dict;
};
