import * as React from "react";
import { Book } from "@mui/icons-material";
import { FilterList, FilterListItem, useGetList } from "react-admin";
import { useState } from "react";
import { useMemo } from "react";
import { string } from "prop-types";

export const CoursesFilter = () => {
  const { data: coursesData } = useGetList("courses");
  const { data: lessonTypesData } = useGetList("lessonTypes");

  return (
    <>
      <FilterList label="Course" icon={<Book />}>
        {coursesData?.map((course) => (
          <FilterListItem
            label={course.level}
            key={course.level}
            value={{ "courseLessons.course.level||$eq||": course.level }}
          />
        ))}
      </FilterList>
      <FilterList label="Lesson Type" icon={<Book />}>
        {lessonTypesData?.map((lessonType) => (
          <FilterListItem
            label={lessonType.type}
            key={lessonType.type}
            value={{ "lessonType.type||$eq||": lessonType.type }}
          />
        ))}
      </FilterList>
    </>
  );
};
