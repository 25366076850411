import { FormTab, TextInput } from "react-admin";
import { ColorInput } from "../../customInputs/ColorInput";

export const FlashCardExercise = (rest: any) => {
  return (
    <FormTab label="Flashcard Exercise" {...rest}>
      {console.log()}
      <TextInput source="content" label="Frontside of the card" />
      <TextInput source="question" label="Backside of the card" />
      <ColorInput
        name="image"
        label="Color"
        helperText="Choose below the background color of the flashcard. It is optional."
      />
      <TextInput source="description" defaultValue={""} hidden />
    </FormTab>
  );
};
