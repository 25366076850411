import {
  FileField,
  FileInput,
  FormDataConsumer,
  FormTab,
  ImageField,
} from "react-admin";
import ReactAudioPlayer from "react-audio-player";
import { ContentEditor } from "../../customInputs/ContentEditor";

export const TitleExercise = (rest: any) => {
  return (
    <FormTab label="Exercise Body" {...rest}>
      <ContentEditor name="content" />
      <ContentEditor name="description" />
      <FileInput source="audio">
        {/* <FileField src="src" /> */}
        {/* {FormDataConsumer}
        <ReactAudioPlayer src="rawFile" controls /> */}
      </FileInput>
      <FileInput source="image">
        <ImageField source="src" title="title" />
      </FileInput>
    </FormTab>
  );
};
