import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ImageField,
  ReferenceField,
  ArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";

export const ExerciseList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="content" />
      <TextField source="description" />
      <TextField source="question" />
      <NumberField source="maxTryCount" />
      <NumberField source="complexity" />
      <NumberField source="totalQuestions" />
      <ImageField source="image" />
      <TextField source="help_hint" />
      <TextField source="video" />
      <TextField source="audio" />
      <TextField source="videoTimestamp" />
      <TextField source="additionalForId" />
      <ReferenceField
        source="exerciseCategoryId"
        reference="exerciseCategories"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="exerciseTypeId" reference="exerciseTypes">
        <TextField source="type" />
      </ReferenceField>
      <ArrayField source="lessonExercises">
        <SingleFieldList>
          <ChipField source="id" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="groupOptions">
        <SingleFieldList>
          <ChipField source="id" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="rightAnswers">
        <SingleFieldList>
          <ChipField source="id" />
        </SingleFieldList>
      </ArrayField>
      <TextField source="additionalFor" />
    </Datagrid>
  </List>
);
