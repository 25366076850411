import {
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
  TextField,
  SimpleForm,
} from "ra-ui-materialui";
import { Create } from "react-admin";

export const CourseCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="level" />
      <TextInput source="language" />
      <ArrayInput source="translates">
        <SimpleFormIterator>
          <ReferenceInput source="languageId" reference="languages">
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="title" />
          <TextInput multiline source="description" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);
