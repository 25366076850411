import { useState } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  ArrayInput,
  SelectInput,
  SimpleFormIterator,
  DateInput,
  Create,
  useRecordContext,
  FormDataConsumer,
  useGetOne,
  Loading,
  useGetMany,
  useGetList,
  TextField,
  TabbedForm,
  FormTab,
  RaRecord,
  useDataProvider,
} from "react-admin";
import { ColorInput } from "../customInputs/ColorInput";
import { FlashCardExercise } from "./subexercises/FlashCardExercise";
import { InstructionExercise } from "./subexercises/InstructionExercise";
import { IntonationExercise } from "./subexercises/IntonationExercise";
import { TitleExercise } from "./subexercises/TitleExercise";

const ExerciseCreateTransformer = async (data: RaRecord) => {
  if (data.image && data.image.hex) {
    data.image = data.image.hex;
  }
  return data;
};

export const ExerciseCreate = (props: any) => {
  const { data } = useGetList("exerciseTypes");

  interface IExerciseType {
    id: string;
    type: string;
  }

  const [currentExerciseType, setExerciseType] = useState<IExerciseType>();
  return (
    <Create transform={ExerciseCreateTransformer}>
      <TabbedForm>
        <FormTab label="Main Details">
          <ArrayInput source="lessonExercises">
            <SimpleFormIterator>
              <TextInput source="lessonId" />
              <NumberInput source="order" />
            </SimpleFormIterator>
          </ArrayInput>
          {/* <TextInput source="content" />
        <TextInput source="description" />
        <NumberInput source="maxTryCount" />
        <NumberInput source="complexity" />
        <NumberInput source="totalQuestions" />
        <TextInput source="image" />
        <TextInput source="help_hint" />
        <TextInput source="video" />
        <TextInput source="audio" />
        <TextInput source="videoTimestamp" />
        <TextInput source="additionalForId" />
 */}

          <ReferenceInput
            source="exerciseCategoryId"
            reference="exerciseCategories"
          >
            <SelectInput optionText="name" optionValue="id" />
          </ReferenceInput>
          <ReferenceInput source="exerciseTypeId" reference="exerciseTypes">
            <SelectInput optionText="type" optionValue="id" />
          </ReferenceInput>
        </FormTab>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            //Data should have loaded by now >> extract the type name
            setExerciseType(
              data?.filter((it) => it.id === formData.exerciseTypeId)[0]
            );
            console.log(formData);
            if (currentExerciseType == null) {
              return;
            }
            switch (currentExerciseType.type) {
              case "instruction":
                console.log("Instruction Exercise");
                return <InstructionExercise {...rest} />;
              case "title":
                return <TitleExercise {...rest} />;
              case "flash_card":
                return <FlashCardExercise {...rest} />;
              case "intonation":
                return <IntonationExercise />;
            }
          }}
        </FormDataConsumer>

        {/* <ArrayInput source="groupOptions">
          <SimpleFormIterator>
            <ReferenceInput source="exerciseId" reference="exercises">
              <SelectInput optionText="id" />
            </ReferenceInput>
            <ArrayInput source="answerOptions">
              <SimpleFormIterator>
                <TextInput source="answer" />
                <NumberInput source="count" />
                <ReferenceInput source="groupOptionId" reference="groupOptions">
                  <SelectInput optionText="id" />
                </ReferenceInput>
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="rightAnswers">
          <SimpleFormIterator>
            <TextInput source="id" />
            <DateInput source="createdAt" />
            <DateInput source="updatedAt" />
            <ArrayInput source="answer">
              <SimpleFormIterator>
                <TextInput source="id" />
                <TextInput source="value" />
              </SimpleFormIterator>
            </ArrayInput>
            <TextInput source="audio" />
            <ReferenceInput source="exerciseId" reference="exercises">
              <SelectInput optionText="id" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput source="additionalFor" /> */}
      </TabbedForm>
    </Create>
  );
};
