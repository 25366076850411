import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  PasswordInput,
  Create,
} from "react-admin";
import { Role } from "../utilities/enums/roles.enum";
import { CreateSelectArrayFromEnum } from "../utilities/utils";

export const PanelUserCreate = (props: any) => {
  const roleDict = CreateSelectArrayFromEnum(Role);
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="username" />
        <TextInput source="email" label="E-mail" />
        <SelectInput source="roles" choices={roleDict} label="Role" />
        <PasswordInput source="password" />
        <ReferenceInput source="organizationId" reference="panelOrganizations">
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
